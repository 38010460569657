import * as route from '../data/routes';

export default [

    {
        name : route.HOME,
        path : '/',
        redirect : '/albums-of/2024',
        meta : {
            title : 'Albums of the Year – Tonight We Dance',
            announcer : {
                message : 'Navigated to homepage'
            }
        }
    },

    {
        name : route.YEAR,
        path : '/albums-of/:year',
        component: () => import(/* webpackChunkName: "year" */ '../pages/CalendarYear.vue'),
        meta : {
            title : 'Albums of the Year – Tonight We Dance',
            announcer : {
                message : 'Navigated to albums of the year page'
            }
        }
    },

    {
        name : route.ROUTE_NOT_FOUND,
        path : '/404/',
        component: () => import(/* webpackChunkName: "page-not-found" */ '../pages/RouteNotFound.vue'),
        meta : {
            title : '404 – Tonight We Dance',
            announcer : {
                message : 'Navigated to page not found'
            }
        }
    },

    // Redirect routes not matched to 404
    {
        path : '/:pathMatch(.*)',
        redirect : '/404/'
    }
];
